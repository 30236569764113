import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {EnvironmentService} from './environment.service';
import {IToken} from '../model/token.model';
import {Client} from 'typesense';
import {ITypesenseResult} from '../model/searchResult.model';

const TYPESENSE_TOKEN_CATEGORY = 'typesense_frontend_user';

@Injectable({
    providedIn: 'root'
})
export class TypesenseService {

    lastToken: IToken
    collectionName: string
    client: Client

    constructor(
        private userService: UserService,
        private env: EnvironmentService
    ) {

        this.collectionName = this.env.currentEnv.typesenseCollection;

        this.userService.tokenObservable.subscribe(tokes => {
            const typesenseToken = tokes.find(token => token.category === TYPESENSE_TOKEN_CATEGORY);

            if (typesenseToken && (!this.lastToken || this.lastToken.token !== typesenseToken.token)) {
                this.lastToken = typesenseToken

                console.log('Found a new typesense token', typesenseToken);

                const tsUrl = new URL(this.env.currentEnv.typesenseUri);

                this.client = new Client({
                    nodes: [{
                        host: tsUrl.host,
                        port: 443,
                        protocol: tsUrl.protocol.replace(':', '')
                    }],
                    apiKey: this.lastToken.token,
                    connectionTimeoutSeconds: 2
                });
            }
        })
    }

    async search(query: string) {
        return await this.rawSearch({
            q: query,
            query_by: 'title,content',
            sort_by: 'followers:desc, created:desc',
            facet_by: 'type_facet,parent_type_facet'
        })
    }

    async rawSearch(searchParameters) {
        if (!this.client) {
            // return an empty response
            return {
                found: 0,
                hits: []
            }
        }

        return await this.client?.collections(this.collectionName)
            .documents()
            .search(searchParameters) as ITypesenseResult
    }
}
