import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ImageProxy} from '../../core/util/image-proxy';
import {DomSanitizer} from '@angular/platform-browser';
import {IAppImage} from '../../core/model/app-image.model';
import {IColSizes} from '../../core/model/colSizes.model';

@Component({
    selector: 'app-img',
    templateUrl: './img.component.html',
    styleUrls: ['./img.component.scss'],
})
export class ImgComponent implements OnInit, OnChanges {

    @Input() image: IAppImage;
    @Input() colSizes: IColSizes;
    @Input() width = '100%';
    @Input() align: 'left' | 'right' | 'top' | 'alone' | 'none' | 'center' = 'none'; // left, right, top, alone, none, center
    @Input() aspectRatio = 0.5; // aspect ratio is height / width of the image
    @Input() roundedCorners: boolean;
    @Input() smartCrop = false;
    @Input() linked = false;

    srcToUse;
    sizes;
    srcset;

    constructor(
        private imageProxy: ImageProxy,
        private sanitizer: DomSanitizer
    ) {
    }

    ngOnInit() {
        this.renderComponent();
    }

    ngOnChanges() {
        this.renderComponent();
    }

    private renderComponent() {
        if (this.image?.src) {
            const attributes = this.imageProxy.getImageAttributes(
                this.image.src, this.width, this.aspectRatio, this.colSizes, this.smartCrop, this.image.focal
            );

            this.srcToUse = this.sanitizer.bypassSecurityTrustResourceUrl(attributes.src);

            this.sizes = attributes.sizes.join(', ');
            this.srcset = attributes.srcset.join(', ');
        }
    }
}
